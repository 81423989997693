import React from 'react';
import { Box, Container, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 2,
        px: 2,
        mt: 'auto',
        mb: '56px',
        borderTop: '1px solid',
        borderColor: 'divider',
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: { xs: 'center', sm: 'flex-start' },
            gap: 1,
          }}
        >
          <Box>
            <Typography variant="caption" color="text.secondary">
              ИП Нетребский Леонид Сергеевич
            </Typography>
            <Typography variant="caption" display="block" color="text.secondary">
              ИНН: 631906096027
            </Typography>
            <Typography variant="caption" color="text.secondary">
              г. Самара, ул. Ленинская, д. 43
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: { xs: 'center', sm: 'flex-end' },
              gap: 0.5,
            }}
          >
            <Link 
              component={RouterLink} 
              to="/offer" 
              target="_blank"
              rel="noopener noreferrer"
              color="text.secondary"
              variant="caption"
              sx={{ 
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              Публичная оферта
            </Link>
            <Link 
              component={RouterLink} 
              to="/privacy" 
              target="_blank"
              rel="noopener noreferrer"
              color="text.secondary"
              variant="caption"
              sx={{ 
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              Политика конфиденциальности
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer; 