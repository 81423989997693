import * as LDClient from 'launchdarkly-js-client-sdk';

interface FeatureFlagUser {
  id: string;
  email?: string;
  custom?: Record<string, any>;
}

class FeatureFlagService {
  public client: LDClient.LDClient | null = null;
  public initialized = false;

  async initialize(clientKey: string, user: FeatureFlagUser) {
    if (this.initialized) return;

    console.log('Initializing LD with user:', user);

    const ldUser = {
      key: user.id,
      email: user.email,
      custom: user.custom
    };

    this.client = LDClient.initialize(clientKey, ldUser);
    
    return new Promise<void>((resolve) => {
      this.client?.on('ready', () => {
        this.initialized = true;
        console.log('LD initialized successfully');
        resolve();
      });
    });
  }

  async getBooleanFlag(flagKey: string, defaultValue: boolean = false): Promise<boolean> {
    if (!this.client || !this.initialized) {
      console.warn('Feature flag client not initialized');
      return defaultValue;
    }
    const result = await this.client.variation(flagKey, defaultValue);
    console.log(`Flag ${flagKey} value:`, result);
    return result;
  }

  async getStringFlag(flagKey: string, defaultValue: string = ''): Promise<string> {
    if (!this.client || !this.initialized) {
      console.warn('Feature flag client not initialized');
      return defaultValue;
    }
    return this.client.variation(flagKey, defaultValue);
  }

  async getJsonFlag<T>(flagKey: string, defaultValue: T): Promise<T> {
    if (!this.client || !this.initialized) {
      console.warn('Feature flag client not initialized');
      return defaultValue;
    }
    return this.client.variation(flagKey, defaultValue);
  }
}

export const featureFlags = new FeatureFlagService();