import React, { createContext, useContext, useEffect, useState } from 'react';
import { featureFlags } from '../services/featureFlags';

interface FeatureFlagContextType {
  isLoading: boolean;
  getBoolean: (key: string, defaultValue?: boolean) => Promise<boolean>;
  getString: (key: string, defaultValue?: string) => Promise<string>;
  getJson: <T>(key: string, defaultValue: T) => Promise<T>;
}

const FeatureFlagContext = createContext<FeatureFlagContextType | undefined>(undefined);

export const FeatureFlagProvider: React.FC<{
  children: React.ReactNode;
  clientKey: string;
  userId: string;
  userEmail?: string;
}> = ({ children, clientKey, userId, userEmail }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initializeFlags = async () => {
      setIsLoading(true);
      try {
        await featureFlags.initialize(clientKey, {
          id: userId,
          email: userEmail,
        });
      } finally {
        setIsLoading(false);
      }
    };

    // Force re-initialization when user changes
    featureFlags.client = null;
    featureFlags.initialized = false;
    initializeFlags();

    return () => {
      featureFlags.client = null;
      featureFlags.initialized = false;
    };
  }, [clientKey, userId, userEmail]);

  const value = {
    isLoading,
    getBoolean: featureFlags.getBooleanFlag.bind(featureFlags),
    getString: featureFlags.getStringFlag.bind(featureFlags),
    getJson: featureFlags.getJsonFlag.bind(featureFlags),
  };

  return (
    <FeatureFlagContext.Provider value={value}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagContext);
  if (context === undefined) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagProvider');
  }
  return context;
};