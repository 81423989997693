import { getFunctions, httpsCallable, Functions } from 'firebase/functions';

interface PaymentStatusResponse {
  success: boolean;
  status: string;
}

interface PaymentResponse {
  success: boolean;
  paymentId: string;
  sbpUrl: string | null;
}

interface PaymentRequest {
  bookingId: string;
  amount: number;
  returnUrl?: string;
  cancelUrl?: string;
  cardId?: string;
}

export class PaymentService {
  private static instance: PaymentService;
  private functions: Functions;

  private constructor() {
    this.functions = getFunctions(undefined, 'europe-west3');
  }

  public static getInstance(): PaymentService {
    if (!PaymentService.instance) {
      PaymentService.instance = new PaymentService();
    }
    return PaymentService.instance;
  }

  public async initiatePayment(
    bookingId: string, 
    amount: number, 
  ): Promise<PaymentResponse> {
    try {
      const initiatePaymentFunction = httpsCallable<PaymentRequest, PaymentResponse>(
        this.functions, 
        'initiatePayment'
      );
      
      const result = await initiatePaymentFunction({
        bookingId,
        amount,
        returnUrl: `${window.location.origin}/bookings/${bookingId}?paymentStatus=success`,
        cancelUrl: `${window.location.origin}/bookings/${bookingId}?paymentStatus=cancelled`,
      });

      return result.data;
    } catch (error) {
      console.error('Error initiating payment:', error);
      throw error;
    }
  }

  async checkPaymentStatus(bookingId: string): Promise<string> {
    try {
      const checkPayment = httpsCallable<any, PaymentStatusResponse>(
        this.functions, 
        'checkPaymentStatus'
      );
      const result = await checkPayment({ bookingId });
      return result.data.status;
    } catch (error) {
      console.error('Error checking payment status:', error);
      throw error;
    }
  }
} 