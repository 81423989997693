import { httpsCallable, getFunctions } from 'firebase/functions';

export const useTestPayment = () => {
  const testPayment = async (paymentId: string | undefined, options?: { 
    isDeadlineExpired?: boolean;
    isRejected?: boolean;
  }) => {
    if (!paymentId) {
      throw new Error('Payment ID is required');
    }

    const testPaymentFunction = httpsCallable(getFunctions(undefined, 'europe-west3'), 'testPayment');
    return testPaymentFunction({ 
      paymentId,
      isDeadlineExpired: options?.isDeadlineExpired || false,
      isRejected: options?.isRejected || false
    });
  };

  return { testPayment };
}; 