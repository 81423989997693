import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, Select, MenuItem, Drawer, Button } from '@mui/material';
import { Spot } from 'shared-lib';

interface EndTimeDrawerProps {
  open: boolean;
  onClose: () => void;
  startSlot: Spot;
  availableEndSlots: Spot[];
  onSelect: (slot: Spot) => void;
  dayPrice: number;
  minimumSlots: number;
}
  const formatPrice = (price: number) => {
    return `${(price / 100).toFixed(0)} ₽`;
  };

  // Функция форматирования длительности
  const formatDuration = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours === 0) {
      return `${remainingMinutes} минут`;
    } else if (remainingMinutes === 0) {
      if (hours === 1) return '1 час';
      if (hours >= 2 && hours <= 4) return `${hours} часа`;
      return `${hours} часов`;
    } else {
      const hoursText = hours === 1 ? 'час' : hours >= 2 && hours <= 4 ? 'часа' : 'часов';
      return `${hours} ${hoursText} ${remainingMinutes} мин`;
    }
  };

const EndTimeDrawer: React.FC<EndTimeDrawerProps> = ({
  open,
  onClose,
  startSlot,
  availableEndSlots,
  onSelect,
  dayPrice,
  minimumSlots,
}) => {
  const [selectedEndSlot, setSelectedEndSlot] = useState<Spot | null>(null);

  // Фильтруем слоты для отображения, оставляя только те, что >= минимального времени
  const displayableSlots = useMemo(() => 
    availableEndSlots.filter((_, index) => 
      index >= Math.min(minimumSlots, availableEndSlots.length) - 1
    ),
    [availableEndSlots, minimumSlots]
  );

  useEffect(() => {
    if (!open) {
      setSelectedEndSlot(null);
    } else if (displayableSlots.length > 0) {
      setSelectedEndSlot(displayableSlots[0]);
    }
  }, [open, displayableSlots]);

  const calculateDuration = (endSlot: Spot) => {
    const startTime = new Date(`2000/01/01 ${startSlot.start}`);
    const endTime = new Date(`2000/01/01 ${endSlot.end}`);
    return (endTime.getTime() - startTime.getTime()) / (1000 * 60);
  };

  const calculateTotalPrice = (endSlot: Spot) => {
    if (!endSlot) return 0;
    
    const startTime = new Date(`2000/01/01 ${startSlot.start}`);
    const endTime = new Date(`2000/01/01 ${endSlot.start}`);
    
    // Суммируем цены всех слотов в выбранном интервале
    const totalPrice = availableEndSlots
      .filter(slot => {
        const slotTime = new Date(`2000/01/01 ${slot.start}`);
        return slotTime >= startTime && slotTime <= endTime;
      })
      .reduce((sum, slot) => sum + (slot.prices || 0), 0);
    
    // Применяем ограничение дневной ценой
    return dayPrice ? Math.min(totalPrice, dayPrice) : totalPrice;
  };

  const handleConfirm = () => {
    if (selectedEndSlot) {
      onSelect(selectedEndSlot);
    }
  };

  const lastSlot = availableEndSlots[availableEndSlots.length - 1];
  const maxDuration = lastSlot ? calculateDuration(lastSlot) : 0;
  const maxHourlyPrice = lastSlot ? calculateTotalPrice(lastSlot) : 0;
  const maxPrice = Math.min(maxHourlyPrice, dayPrice);

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxHeight: '80vh' }
      }}
    >
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Выбор времени бронирования
        </Typography>
        
        <Typography variant="subtitle1" gutterBottom>
          Начало: {startSlot.start}
        </Typography>

        <Typography variant="body2" color="text.secondary" gutterBottom>
          Максимально доступное время: до {lastSlot?.end} ({formatDuration(maxDuration)}) — {formatPrice(maxPrice)}
        </Typography>

        <Box sx={{ mt: 3 }}>
          <Typography gutterBottom>Время окончания:</Typography>
          <Select
            data-testid="end-time-select"
            fullWidth
            value={selectedEndSlot?.end || ''}
            onChange={(e) => {
              const slot = displayableSlots.find(s => s.end === e.target.value);
              if (slot) setSelectedEndSlot(slot);
            }}
          >
            {displayableSlots.map((slot, index) => {
              const duration = calculateDuration(slot);
              const price = calculateTotalPrice(slot);
              return (
                <MenuItem 
                  key={index} 
                  value={slot.end}
                >
                  {`${slot.end} (${formatDuration(duration)} - ${formatPrice(price)})`}
                </MenuItem>
              );
            })}
          </Select>
        </Box>

        {selectedEndSlot && (
          <>
            <Box sx={{ mt: 4 }}>
              <Typography gutterBottom>Выбранное время:</Typography>
              <Typography variant="h6">
                {`${startSlot.start} — ${selectedEndSlot.end}`}
              </Typography>
              <Typography color="text.secondary">
                {formatDuration(calculateDuration(selectedEndSlot))}
              </Typography>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6" gutterBottom>
                Итоговая цена: {formatPrice(calculateTotalPrice(selectedEndSlot))}
              </Typography>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleConfirm}
                sx={{ mt: 2 }}
              >
                Подтвердить выбор
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default EndTimeDrawer; 