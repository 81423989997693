import React, { useState, useCallback, useEffect } from 'react';
import { 
  Stepper, Step, StepLabel, 
  useMediaQuery, useTheme, Box, Button,
  Typography, Link, Alert, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { Office, FullScreenDrawer, Spot, Client } from 'shared-lib';
import { useAvailableOfficeSlots, useCreateBooking } from '../../hooks/useBookingQueries';
import DateTimeSelector from '../DateTimeSelector';
import BookingConfirmationStep from './BookingConfirmationStep';
import { useNavigate } from 'react-router-dom';
import { User } from 'firebase/auth';
import { UserService } from 'shared-lib';
import { PaymentService } from 'src/services/PaymentService';

interface OfficeBookingWizardProps {
  office: Office;
  onClose: () => void;
  user: User | null;
}

const steps = ['Выбор даты и времени', 'Подтверждение'];

const OfficeBookingWizard: React.FC<OfficeBookingWizardProps> = ({
  office,
  onClose,
  user
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedSlots, setSelectedSlots] = useState<Spot[]>([]);
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showAuthDialog, setShowAuthDialog] = useState(false);
  const [showVerificationDialog, setShowVerificationDialog] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState<boolean | null>(null);
  const navigate = useNavigate();
  const userService = UserService.getInstance();
  const createBookingMutation = useCreateBooking();

  useEffect(() => {
    const checkVerification = async () => {
      if (user) {
        const verified = await userService.isUserVerified(user.uid);
        setIsUserVerified(verified);
      }
    };
    checkVerification();
  }, [user, userService]);

  const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
  const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);

  const {
    data: availableSlots = [],
    isLoading: isSlotsLoading,
    error: slotsError
  } = useAvailableOfficeSlots(office.id, startOfMonth, endOfMonth);

  const handleDateSelect = useCallback((date: Date) => {
    setSelectedDate(date);
    setSelectedSlots([]);
  }, []);

  const handleSlotsSelect = useCallback((slots: Spot[]) => {
    setSelectedSlots(slots);
  }, []);

  const handleMonthChange = useCallback((date: Date) => {
    setCurrentMonth(date);
  }, []);

  const handleNext = async () => {
    if (!user) {
      setShowAuthDialog(true);
    } else if (!isUserVerified) {
      setShowVerificationDialog(true);
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const getTelegramLink = () => {
    return 'https://t.me/netolga84';
  };

  const renderStepContent = (step: number) => {
    if (slotsError) {
      return (
        <Alert 
          severity="error" 
          sx={{ mb: 2 }}
        >
          <Typography gutterBottom>
            Не удалось загрузить доступное время для бронирования.
          </Typography>
          <Typography>
            Пожалуйста, напишите в Telegram{' '}
            <Link 
              href={getTelegramLink()}
              target="_blank" 
              rel="noopener noreferrer"
              sx={{ color: 'inherit', textDecoration: 'underline' }}
            >
              @netolga84
            </Link>
            {' '}для бронирования.
          </Typography>
        </Alert>
      );
    }

    switch (step) {
      case 0:
        return (
          <>
            <DateTimeSelector
              availableSlots={availableSlots}
              selectedDate={selectedDate}
              selectedSlots={selectedSlots}
              onDateSelect={handleDateSelect}
              onSlotsSelect={handleSlotsSelect}
              isMobile={isMobile}
              onMonthChange={handleMonthChange}
              currentMonth={currentMonth}
              isLoading={isSlotsLoading}
              allowMultipleSlots={office.bookingSettings?.allow_multiple_slots}
              duration={office.duration.duration_without_pause}
              dayPrice={office.price.day_price}
              minimumSlots={office.bookingSettings?.minimum_slots || 1}
            />
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={!selectedDate || selectedSlots.length === 0}
              >
                Далее
              </Button>
            </Box>
          </>
        );
      case 1:
        return (
          <BookingConfirmationStep
            selectedDate={selectedDate!}
            selectedSlots={selectedSlots}
            office={office}
            onBack={handleBack}
            onConfirm={handleConfirm}
          />
        );
      default:
        return null;
    }
  };

  const calculateTotalPrice = () => {
    const totalPrice = selectedSlots.reduce((sum, slot) => sum + (slot.prices || 0), 0);
    if (office.bookingSettings.allow_multiple_slots && office.price.day_price) {
        return Math.min(totalPrice, office.price.day_price);
    }
    
    return totalPrice;
  };

  const handleConfirm = async (comment: string, client: Client | null): Promise<string> => {
    if (!selectedDate || selectedSlots.length === 0 || !user) {
      throw new Error("Missing required booking data");
    }

    const newDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()));

    try {
      const bookingId = await createBookingMutation.mutateAsync({
        locationId: office.id,
        selectedDaySpots: {
          date: newDate.toISOString().split('T')[0],
          spots: selectedSlots
        },
        userId: user.uid,
        comment,
        clientData: client,
        totalPrice: calculateTotalPrice()
      });

      const isAdmin = office?.admins?.some(adminRef => adminRef.id === user?.uid);
      
      if (!isAdmin) {
        const paymentService = PaymentService.getInstance();
        await paymentService.initiatePayment(
          bookingId, 
          calculateTotalPrice()
        );
      }
      
      navigate(`/bookings/${bookingId}`);
      onClose();
      return bookingId;
    } catch (error) {
      console.error("Error creating booking:", error);
      throw error;
    }
  };

  const handleLogin = () => {
    onClose();
    navigate('/login');
  };

  const canMoveToStep = (stepIndex: number) => {
    // Всегда можно вернуться на предыдущие шаги
    if (stepIndex < activeStep) return true;
    
    // Для перехода на шаг подтверждения нужно выбрать дату и слоты
    if (stepIndex === 1) {
      return selectedDate !== null && selectedSlots.length > 0;
    }

    return false;
  };

  const handleStepClick = (stepIndex: number) => {
    if (canMoveToStep(stepIndex)) {
      setActiveStep(stepIndex);
    }
  };

  return (
    <>
      <FullScreenDrawer
        open={true}
        onClose={onClose}
        title={office.name}
      >
        <Stepper activeStep={activeStep} sx={{ mb: 2 }}>
          {steps.map((label, index) => (
            <Step 
              key={label}
              sx={{ 
                cursor: canMoveToStep(index) ? 'pointer' : 'default',
                '& .MuiStepLabel-root': {
                  '&:hover': {
                    backgroundColor: canMoveToStep(index) ? 'action.hover' : 'transparent'
                  }
                }
              }}
              onClick={() => handleStepClick(index)}
            >
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {renderStepContent(activeStep)}
      </FullScreenDrawer>

      <Dialog open={showAuthDialog} onClose={() => setShowAuthDialog(false)}>
        <DialogTitle>Требуется авторизация</DialogTitle>
        <DialogContent>
          <Typography>
            Для продолжения бронирования необходимо войти в систему или зарегистрироваться.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAuthDialog(false)}>Отмена</Button>
          <Button onClick={handleLogin} variant="contained" color="primary">
            Войти
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showVerificationDialog} onClose={() => setShowVerificationDialog(false)}>
        <DialogTitle>Требуется подтверждение аккаунта</DialogTitle>
        <DialogContent>
          <Typography>
            Для возможности бронирования ваш аккаунт должен быть подтвержден администратором. 
            Пожалуйста, свяжитесь с администрацией для подтверждения аккаунта.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowVerificationDialog(false)}>
            Понятно
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OfficeBookingWizard; 