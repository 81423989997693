import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { BookingService } from '../services/BookingService';
import { DaySpots, OfficeService } from 'shared-lib';
import { useEffect } from 'react';
import { PublicServiceInfoService } from '../services/PublicServiceInfoService';
import { getFunctions, httpsCallable } from 'firebase/functions';

const bookingService = BookingService.getInstance();
const officeService = OfficeService.getInstance();
const publicServiceInfoService = PublicServiceInfoService.getInstance();

export const useBooking = (bookingId: string | undefined) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!bookingId) return;

    const unsubscribe = bookingService.subscribeToBooking(
      bookingId,
      (booking) => {
        queryClient.setQueryData(['booking', bookingId], booking);
      },
      (error) => {
        console.error('Booking subscription error:', error);
      }
    );

    return () => unsubscribe();
  }, [bookingId, queryClient]);

  return useQuery({
    queryKey: ['booking', bookingId],
    queryFn: () => bookingId ? bookingService.getBookingById(bookingId) : null,
    enabled: !!bookingId,
  });
};

export const useOffice = (officeId: string | undefined) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!officeId) return;

    const unsubscribe = officeService.subscribeToOffice(officeId, (office) => {
      queryClient.setQueryData(['office', officeId], office);
    });

    return () => unsubscribe();
  }, [officeId, queryClient]);

  return useQuery({
    queryKey: ['office', officeId],
    queryFn: () => officeId ? officeService.getOfficeById(officeId) : null,
    enabled: !!officeId,
  });
};

export const useCancelBooking = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: ({ bookingId, comment }: { bookingId: string; comment: string }) => 
      bookingService.cancelBooking(bookingId, comment),
    onSuccess: (_, { bookingId }) => {
      queryClient.invalidateQueries({ queryKey: ['booking', bookingId] });
      queryClient.invalidateQueries({ queryKey: ['availableOfficeSlots'] });
      queryClient.invalidateQueries({ queryKey: ['availableSlotsForUser'] });
    },
  });
};

export const usePublicServiceInfo = (userId: string, serviceId: string) => {
  return useQuery({
    queryKey: ['publicServiceInfo', userId, serviceId],
    queryFn: () => publicServiceInfoService.getPublicServiceInfo(userId, serviceId),
    enabled: !!userId && !!serviceId,
  });
};

export const useAvailableSlotsForUser = (serviceId: string, userId: string, startDate: Date, endDate: Date) => {
  return useQuery({
    queryKey: ['availableSlotsForUser', serviceId, userId, startDate.toISOString(), endDate.toISOString()],
    queryFn: () => officeService.fetchAvailableSlotsForUser(serviceId, userId, startDate, endDate),
    enabled: !!serviceId && !!userId,
    staleTime: 1000 * 60 * 5,
    placeholderData: keepPreviousData => keepPreviousData,
  });
};

export const useAvailableOfficeSlots = (officeId: string, startDate: Date, endDate: Date) => {
  return useQuery({
    queryKey: ['availableOfficeSlots', officeId, startDate.toISOString(), endDate.toISOString()],
    queryFn: async () => {
      const office = await officeService.getOfficeById(officeId);
      if (!office) {
        throw new Error('Office not found');
      }
      return officeService.fetchAvailableSlots(office, startDate, endDate);
    },
    enabled: !!officeId,
    staleTime: 1000 * 60 * 5,
    placeholderData: keepPreviousData => keepPreviousData,
  });
};

export const useCreateBooking = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: ({
      locationId,
      selectedDaySpots,
      userId,
      comment,
      clientData,
      totalPrice,
      serviceId
    }: {
      locationId: string;
      selectedDaySpots: DaySpots;
      userId: string;
      comment: string;
      clientData: { name: string; phone: string; email: string; } | null;
      serviceId?: string;
      totalPrice: number;
    }) => bookingService.createBooking(
      locationId,
      selectedDaySpots,
      userId,
      comment,
      clientData,
      totalPrice,
      serviceId
    ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['availableOfficeSlots'] });
      queryClient.invalidateQueries({ queryKey: ['availableSlotsForUser'] });
    },
  });
};

export const useCompleteBooking = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({ bookingId, photos, comment }: {
      bookingId: string;
      photos: string[];
      comment?: string;
    }) => {
      const completeBooking = httpsCallable(getFunctions(undefined, 'europe-west3'), 'completeBooking');
      await completeBooking({ bookingId, photos, comment });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bookings'] });
    }
  });
};

export const useStartBooking = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({ bookingId, photos, comment }: {
      bookingId: string;
      photos: string[];
      comment?: string;
    }) => {
      const startBooking = httpsCallable(getFunctions(undefined, 'europe-west3'), 'startBooking');
      await startBooking({ bookingId, photos, comment });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bookings'] });
    }
  });
}; 