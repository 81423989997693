import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SwipeableDrawer, Box, Typography, IconButton, useTheme, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
const FullScreenDrawer = ({ open, onClose, onOpen = () => { }, title, children }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (_jsx(SwipeableDrawer, Object.assign({ anchor: "bottom", open: open, onClose: onClose, onOpen: onOpen, swipeAreaWidth: 56, disableSwipeToOpen: false, ModalProps: {
            keepMounted: true,
        }, PaperProps: {
            sx: {
                height: '100%',
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16
            }
        } }, { children: _jsxs(Box, Object.assign({ sx: {
                px: 2,
                py: 3,
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            } }, { children: [_jsxs(Box, Object.assign({ sx: { display: 'flex', justifyContent: 'space-between', mb: 2 } }, { children: [title && _jsx(Typography, Object.assign({ variant: "h6" }, { children: title })), _jsx(IconButton, Object.assign({ onClick: onClose, sx: { ml: 'auto' } }, { children: _jsx(CloseIcon, {}) }))] })), _jsx(Box, Object.assign({ sx: { flexGrow: 1, overflow: 'auto' } }, { children: children }))] })) })));
};
export default FullScreenDrawer;
