import { collection, doc, query, where, onSnapshot, Unsubscribe, getDocs, orderBy, limit, startAfter, getDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { DaySpots, db } from 'shared-lib';
import { Booking, Pass } from 'shared-lib';

interface Client {
  id?: string;  // Делаем id опциональным
  name: string;
  phone: string;
  email: string;
}

export class BookingService {
  private static instance: BookingService;
  private functions = getFunctions(undefined, 'europe-west3');

  private constructor() {}

  public static getInstance(): BookingService {
    if (!BookingService.instance) {
      BookingService.instance = new BookingService();
    }
    return BookingService.instance;
  }

  public async createBooking(
    officeId: string,
    daySpots: DaySpots,
    providerId: string,
    comment: string,
    client: Client | null,
    totalPrice: number,
    serviceId?: string
  ): Promise<string> {
    if (!daySpots.spots.length) {
      throw new Error('At least one slot must be selected');
    }

    try {
      // Sort slots by start time to find earliest and latest
      const sortedSpots = [...daySpots.spots].sort((a, b) => 
        a.start.localeCompare(b.start)
      );

      const combinedSlot = {
        date: daySpots.date,
        spot: {
          start: sortedSpots[0].start,
          end: sortedSpots[sortedSpots.length - 1].end,
          prices: totalPrice,
          quantity: 1
        }
      };

      const createBookingFunction = httpsCallable(this.functions, 'createBooking');
      const result = await createBookingFunction({ officeId, selectedSlot: combinedSlot, providerId, comment, client, serviceId });
      
      const { success, bookingId } = result.data as { success: boolean, bookingId: string };

      if (success) {
        return bookingId;
      } else {
        throw new Error('Failed to create booking');
      }
    } catch (error) {
      console.error("Error creating booking:", error);
      throw error;
    }
  }

  public subscribeToUserBookings(userId: string, callback: (bookings: Booking[]) => void): Unsubscribe {
    const q = query(
      collection(db, 'bookings'),
      where('uid', '==', userId)
    );

    return onSnapshot(q, (querySnapshot) => {
      const bookings: Booking[] = [];
      querySnapshot.forEach((doc) => {
        bookings.push({ id: doc.id, ...doc.data() } as Booking);
      });
      callback(bookings);
    });
  }

  public async fetchUserBookings(userId: string, lastBooking?: Booking | null): Promise<Booking[]> {
    console.log('Fetching bookings for user:', userId);
    const now = new Date();
    let q = query(
      collection(db, 'bookings'),
      where('uid', '==', userId),
      where('reserved_on', '>=', now.toISOString()),
      orderBy('reserved_on', 'asc'),
      limit(10)
    );

    if (lastBooking) {
      q = query(q, 
        startAfter(lastBooking.reserved_on)
      );
    }

    const querySnapshot = await getDocs(q);
    console.log('Fetched bookings count:', querySnapshot.size);
    return querySnapshot.docs.map(doc => {
      const data = doc.data();
      console.log('Booking data:', data);
      return this.convertBookingData(doc);
    });
  }

  public async fetchPastUserBookings(userId: string, limitNumber: number = 11): Promise<Booking[]> {
    console.log('Fetching past bookings for user:', userId);
    const now = new Date();
    const q = query(
      collection(db, 'bookings'),
      where('uid', '==', userId),
      where('reserved_on', '<', now.toISOString()),
      orderBy('reserved_on', 'desc'),
      limit(limitNumber)
    );

    const querySnapshot = await getDocs(q);
    console.log('Fetched past bookings count:', querySnapshot.size);
    return querySnapshot.docs.map(doc => this.convertBookingData(doc));
  }

  public async getBookingById(bookingId: string): Promise<Booking> {
    const docRef = doc(db, 'bookings', bookingId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return this.convertBookingData(docSnap);
    } else {
      throw new Error('Booking not found');
    }
  }

  public subscribeToBooking(
    bookingId: string, 
    callback: (booking: Booking) => void,
    errorCallback: (error: Error) => void
  ): Unsubscribe {
    const docRef = doc(db, 'bookings', bookingId);
    return onSnapshot(docRef, 
      (docSnap) => {
        if (docSnap.exists()) {
          const booking = this.convertBookingData(docSnap);
          callback(booking);
        } else {
          errorCallback(new Error('Booking not found'));
        }
      },
      (error) => {
        errorCallback(error);
      }
    );
  }

  private convertBookingData(docSnap: any): Booking {
    const data = docSnap.data();
    return {
      id: docSnap.id,
      ...data,
      createdAt: data.createdAt ? data.createdAt.toDate().toISOString() : new Date().toISOString(),
      startTime: data.startTime ? data.startTime.toDate() : new Date(),
      endTime: data.endTime ? data.endTime.toDate() : new Date(),
      passes: data.passes ? this.convertPasses(data.passes) : undefined
    } as Booking;
  }

  private convertPasses(passes: Record<string, unknown>): { [deviceId: string]: Pass } {
    const convertedPasses: { [deviceId: string]: Pass } = {};
    for (const [deviceId, pass] of Object.entries(passes)) {
      if (typeof pass === 'object' && pass !== null) {
        const typedPass = pass as Partial<Pass>;
        convertedPasses[deviceId] = {
          password: typedPass.password,
          passwordId: typedPass.passwordId,
          status: typedPass.status,
          attempts: typedPass.attempts,
          startTime: typedPass.startTime ? this.convertTimestamp(typedPass.startTime) : new Date(),
          endTime: typedPass.endTime ? this.convertTimestamp(typedPass.endTime) : new Date(),
          name: typedPass.name || 'Дверь',
          instructions: typedPass.instructions
        };
      } else {
        console.warn(`Unexpected pass data for device ${deviceId}:`, pass);
        convertedPasses[deviceId] = {
          startTime: new Date(),
          endTime: new Date(),
          name: 'Дверь',
          password: undefined,
          passwordId: undefined,
          status: undefined,
          attempts: undefined
        };
      }
    }
    return convertedPasses;
  }

  private convertTimestamp(time: unknown): Date {
    if (time && typeof time === 'object' && 'toDate' in time && typeof time.toDate === 'function') {
      return time.toDate();
    } else if (time instanceof Date) {
      return time;
    } else {
      return new Date();
    }
  }

  public async cancelBooking(bookingId: string, comment: string): Promise<void> {
    if (!bookingId) {
      throw new Error('Booking ID is required');
    }

    try {
      const cancelBookingFunction = httpsCallable(this.functions, 'cancelBooking');
      const result = await cancelBookingFunction({ bookingId, comment });
      
      const { success, message } = result.data as { success: boolean, message: string };

      if (!success) {
        throw new Error(message || 'Failed to cancel booking');
      }

    } catch (error) {
      console.error("Error cancelling booking:", error);
      throw error;
    }
  }
}
