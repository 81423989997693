import React, { useState } from 'react';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, AuthError, updateProfile } from 'firebase/auth';
import { auth, db } from 'shared-lib';
import { TextField, Button, Typography, Box, Link, FormControlLabel, Checkbox } from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [formattedPhone, setFormattedPhone] = useState('');
  const [error, setError] = useState('');
  const [isRegistering, setIsRegistering] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('register') === 'true';
  });
  const navigate = useNavigate();
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [agreeToPrivacy, setAgreeToPrivacy] = useState(false);
  const [touched, setTouched] = useState<Record<string, boolean>>({});

  const handleAuth = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    try {
      if (isRegistering) {
        if (!agreeToTerms || !agreeToPrivacy) {
          setError('Необходимо принять условия оферты и политики конфиденциальности');
          return;
        }
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        await updateProfile(userCredential.user, {
          displayName: `${firstName} ${lastName}`,
        });
        
        const unformattedPhone = formattedPhone.replace(/\D/g, '');
        
        try {
          await setDoc(doc(db, 'users', userCredential.user.uid), {
            uid: userCredential.user.uid,
            firstName,
            lastName,
            email,
            phone: unformattedPhone.startsWith('+') ? unformattedPhone : `+${unformattedPhone}`
          });
        } catch (firestoreError) {
          console.error("Error writing user data to Firestore:", firestoreError);
          throw new Error("Failed to complete registration. Please try again.");
        }
      } else {
        await signInWithEmailAndPassword(auth, email, password);
      }
      navigate('/');
    } catch (error) {
      handleAuthError(error as AuthError);
    }
  };

  const handleAuthError = (error: AuthError) => {
    switch (error.code) {
      case 'auth/email-already-in-use':
        setError('Этот email уже используется. Попробуйте войти.');
        break;
      case 'auth/weak-password':
        setError('Пароль слишком слабый. Используйте не менее 6 символов.');
        break;
      case 'auth/invalid-email':
        setError('Неверный формат email.');
        break;
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        setError('Неверный email или пароль.');
        break;
      case 'auth/too-many-requests':
        setError('Слишком много попыток входа. Попробуйте позже.');
        break;
      default:
        setError('Произошла ошибка при аутентификации. Попробуйте еще раз.');
    }
  };

  const handleResetPassword = async () => {
    if (!email) {
      setError('Пожалуйста, введите email для сброса пароля');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setError('Инструкции по сбросу пароля отправлены на ваш email');
    } catch (error) {
      if ((error as AuthError).code === 'auth/user-not-found') {
        setError('Пользователь с таким email не найден');
      } else {
        setError('Ошибка при отправке инструкций по сбросу пароля');
      }
    }
  };

  const formatPhoneNumber = (value: string) => {
    const phoneNumber = value.replace(/\D/g, '');
    if (phoneNumber.length === 0) {
      return '';
    }
    if (phoneNumber.length <= 1) {
      return `+${phoneNumber}`;
    }
    if (phoneNumber.length <= 4) {
      return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(1)}`;
    }
    if (phoneNumber.length <= 7) {
      return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4)}`;
    }
    if (phoneNumber.length <= 9) {
      return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7)}`;
    }
    return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 9)}-${phoneNumber.slice(9, 11)}`;
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setFormattedPhone(formattedPhone);
  };

  const handleBlur = (field: string) => {
    setTouched(prev => ({ ...prev, [field]: true }));
  };

  const getHelperText = (field: string, value: string) => {
    if (touched[field] && !value) {
      return 'Пожалуйста, заполните это поле';
    }
    return '';
  };

  return (
    <Box sx={{ 
      maxWidth: 300, 
      margin: 'auto', 
      mt: 4,
      mb: '100px'
    }}>
      <Typography variant="h5" component="h1" gutterBottom>
        {isRegistering ? 'Регистрация' : 'Вход'}
      </Typography>
      <form onSubmit={handleAuth} noValidate>
        {isRegistering && (
          <>
            <TextField
              label="Имя"
              fullWidth
              margin="normal"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              onBlur={() => handleBlur('firstName')}
              required
              error={touched['firstName'] && !firstName}
              helperText={getHelperText('firstName', firstName)}
            />
            <TextField
              label="Фамилия"
              fullWidth
              margin="normal"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              onBlur={() => handleBlur('lastName')}
              required
              error={touched['lastName'] && !lastName}
              helperText={getHelperText('lastName', lastName)}
            />
            <TextField
              label="Телефон"
              fullWidth
              margin="normal"
              value={formattedPhone}
              onChange={handlePhoneChange}
              onBlur={() => handleBlur('phone')}
              placeholder="+7 (___) ___-__-__"
              required
              error={touched['phone'] && !formattedPhone}
              helperText={getHelperText('phone', formattedPhone)}
            />
          </>
        )}
        <TextField
          label="Email"
          type="email"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={() => handleBlur('email')}
          required
          error={touched['email'] && !email}
          helperText={getHelperText('email', email)}
        />
        <TextField
          label="Пароль"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onBlur={() => handleBlur('password')}
          required
          error={touched['password'] && !password}
          helperText={getHelperText('password', password)}
        />
        {isRegistering && (
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
            <FormControlLabel
              sx={{
                margin: 0,
                alignItems: 'flex-start',
                '& .MuiCheckbox-root': {
                  padding: '0 9px 0 0',
                }
              }}
              control={
                <Checkbox
                  checked={agreeToTerms}
                  onChange={(e) => setAgreeToTerms(e.target.checked)}
                  required
                />
              }
              label={
                <Typography 
                  variant="body2" 
                  sx={{ 
                    display: 'inline',
                    wordBreak: 'break-word'
                  }}
                >
                  Я принимаю условия{' '}
                  <Link 
                    component={RouterLink} 
                    to="/offer" 
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ cursor: 'pointer' }}
                  >
                    публичной оферты
                  </Link>
                </Typography>
              }
            />
            <FormControlLabel
              sx={{
                margin: 0,
                alignItems: 'flex-start',
                '& .MuiCheckbox-root': {
                  padding: '0 9px 0 0',
                }
              }}
              control={
                <Checkbox
                  checked={agreeToPrivacy}
                  onChange={(e) => setAgreeToPrivacy(e.target.checked)}
                  required
                />
              }
              label={
                <Typography 
                  variant="body2" 
                  sx={{ 
                    display: 'inline',
                    wordBreak: 'break-word'
                  }}
                >
                  Я согласен на{' '}
                  <Link 
                    component={RouterLink} 
                    to="/privacy" 
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ cursor: 'pointer' }}
                  >
                    обработку персональных данных
                  </Link>
                </Typography>
              }
            />
          </Box>
        )}
        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
          {isRegistering ? 'Зарегистрироваться' : 'Войти'}
        </Button>
      </form>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Link
          component="button"
          variant="body2"
          onClick={() => setIsRegistering(!isRegistering)}
        >
          {isRegistering ? 'Уже есть аккаунт? Войти' : 'Создать аккаунт'}
        </Link>
        <Link
          component="button"
          variant="body2"
          onClick={handleResetPassword}
        >
          Забыли пароль?
        </Link>
      </Box>
    </Box>
  );
};

export default Login;