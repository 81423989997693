import React from 'react';
import { Box, Alert, AlertTitle, Link, Collapse, Button } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import { AppBar } from 'shared-lib';
import BottomAppBar from './BottomAppBar';
import { useAuth } from 'shared-lib';
import { useMediaQuery, useTheme } from '@mui/material';
import Footer from './Footer';

const Layout: React.FC = () => {
  const { user, isVerified } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const getTelegramLink = () => {
    return 'https://t.me/netolga84';
  };

  const getPageInfo = () => {
    return { 
      title: 'Аренда Вместе: Сервис совместной аренды офисов',
      showBackButton: false,
      rightContent: !user ? (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button 
            color="inherit" 
            onClick={() => navigate('/login')}
          >
            Войти
          </Button>
          <Button 
            color="inherit" 
            variant="outlined"
            onClick={() => navigate('/login?register=true')}
          >
            Регистрация
          </Button>
        </Box>
      ) : undefined
    };
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      minHeight: '100vh',
    }}>
      <AppBar getPageInfo={getPageInfo} />
      {user && isVerified === false && (
        <Collapse in={true}>
          <Alert 
            severity="warning"
            sx={{ 
              position: 'sticky',
              top: isMobile ? 56 : 64,
              zIndex: 1000,
              borderRadius: 0
            }}
          >
            <AlertTitle>Аккаунт не подтвержден</AlertTitle>
            Для активации аккаунта, пожалуйста, напишите в Telegram{' '}
            <Link 
              href={getTelegramLink()}
              target="_blank" 
              rel="noopener noreferrer"
              sx={{ color: 'inherit', textDecoration: 'underline' }}
            >
              @netolga84
            </Link>
            {' '}с просьбой активировать ваш аккаунт.
          </Alert>
        </Collapse>
      )}
      <Box sx={{ 
        flex: 1,
        width: '100%',
      }}>
        <Outlet />
      </Box>
      <Footer />
      <BottomAppBar />
    </Box>
  );
};

export default Layout;
