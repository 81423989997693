import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const PrivacyPolicy: React.FC = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Политика обработки персональных данных
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          1. Общие положения
        </Typography>
        <Typography paragraph>
          ИП Нетребский Леонид Сергеевич (ИНН: 631906096027) осуществляет обработку персональных данных 
          в соответствии с Федеральным законом "О персональных данных" от 27.07.2006 N 152-ФЗ для исполнения 
          договора аренды помещений, стороной которого является субъект персональных данных.
        </Typography>
        <Typography paragraph>
          Контактные данные:
        </Typography>
        <Typography component="ul">
          <li>ИП Нетребский Леонид Сергеевич</li>
          <li>ИНН: 631906096027</li>
          <li>Email: leonid@netrebskiy.ru</li>
          <li>Telegram: @kindofdev</li>
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          2. Цель обработки персональных данных
        </Typography>
        <Typography paragraph>
          Обработка персональных данных осуществляется в следующих целях:
        </Typography>
        <Typography component="ul">
          <li>Заключение и исполнение договора аренды помещений</li>
          <li>Идентификация пользователя в системе</li>
          <li>Предоставление доступа к арендуемым помещениям</li>
          <li>Коммуникация по вопросам аренды</li>
          <li>Оформление и отправка документов (включая кассовые чеки)</li>
          <li>Интеграция с календарями и системами оповещений по желанию пользователя</li>
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          3. Перечень обрабатываемых данных
        </Typography>
        <Typography paragraph>
          Обрабатываются следующие персональные данные:
        </Typography>
        <Typography component="ul">
          <li>Фамилия, имя (для идентификации)</li>
          <li>Адрес электронной почты (для отправки документов и уведомлений)</li>
          <li>Номер телефона (для связи)</li>
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          4. Порядок обработки и передача данных
        </Typography>
        <Typography paragraph>
          Осуществляется сбор, запись, систематизация, накопление, хранение, уточнение 
          (обновление, изменение), извлечение, использование персональных данных.
        </Typography>
        <Typography paragraph>
          Осуществляется трансграничная передача персональных данных на территории иностранных 
          государств, обеспечивающих адекватную защиту прав субъектов персональных данных, для:
        </Typography>
        <Typography component="ul">
          <li>Обработки данных в облачных системах Google Cloud Platform (регион europe-west3, Франкфурт, Германия)</li>
          <li>Интеграции с сервисом Google Calendar (при подключении календаря пользователем)</li>
          <li>Интеграции с иными сервисами по запросу пользователя</li>
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          5. Защита данных
        </Typography>
        <Typography paragraph>
          Принимаются необходимые правовые, организационные и технические меры для защиты 
          персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, 
          блокирования, копирования, предоставления, распространения персональных данных, а также от иных 
          неправомерных действий в отношении персональных данных.
        </Typography>
        <Typography paragraph>
          Для защиты персональных данных применяются следующие меры:
        </Typography>
        <Typography component="ul">
          <li>Шифрование данных при передаче и хранении</li>
          <li>Строгий контроль доступа к данным</li>
          <li>Регулярный аудит систем безопасности</li>
          <li>Многофакторная аутентификация при доступе к системам управления</li>
        </Typography>
        <Typography paragraph>
          При работе с Google Calendar:
        </Typography>
        <Typography component="ul">
          <li>Запрашиваются только минимально необходимые разрешения для работы сервиса (calendar.events.owned и calendar.readonly)</li>
          <li>Токены доступа хранятся в зашифрованном виде</li>
          <li>Доступ к календарю предоставляется только владельцу данных</li>
          <li>Данные календаря не передаются третьим лицам</li>
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          6. Согласие на обработку
        </Typography>
        <Typography paragraph>
          Регистрируясь в сервисе, пользователь дает согласие на:
        </Typography>
        <Typography component="ul">
          <li>Обработку персональных данных в целях, указанных в разделе 2</li>
          <li>Трансграничную передачу данных в страны, обеспечивающие адекватную защиту прав субъектов персональных данных</li>
          <li>Передачу данных, необходимых для проведения платежей</li>
          <li>Передачу данных в интегрированные сервисы при их подключении пользователем</li>
        </Typography>
        <Typography paragraph>
          Согласие действует с момента регистрации в сервисе до момента удаления аккаунта или отзыва согласия.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          7. Срок обработки
        </Typography>
        <Typography paragraph>
          Персональные данные обрабатываются до момента удаления аккаунта пользователем или до момента 
          отзыва согласия на обработку персональных данных. После удаления аккаунта сохраняются только 
          те данные, которые необходимы для соблюдения требований законодательства РФ о бухгалтерском 
          учете и налоговой отчетности.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          8. Права субъекта персональных данных
        </Typography>
        <Typography paragraph>
          В соответствии с 152-ФЗ субъект персональных данных имеет право:
        </Typography>
        <Typography component="ul">
          <li>Получать информацию, касающуюся обработки его персональных данных</li>
          <li>Требовать уточнения его персональных данных, их блокирования или уничтожения</li>
          <li>Отозвать согласие на обработку персональных данных</li>
          <li>Требовать прекращения обработки персональных данных</li>
          <li>Обжаловать действия или бездействие оператора</li>
        </Typography>
        <Typography paragraph>
          Для реализации своих прав и законных интересов субъект персональных данных может 
          обратиться к оператору по указанным в разделе 1 контактным данным.
        </Typography>
      </Box>

      <Typography variant="body2" color="text.secondary" sx={{ mt: 4 }}>
        Дата последнего обновления: 20.01.2025
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy; 