import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Typography, Box, Paper, Button, CircularProgress, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Alert, AlertTitle } from '@mui/material';
import { getStatusText, getStatusColor, PaymentMethod, Booking, Office } from 'shared-lib';
import { useBooking, useOffice, useCancelBooking, useCompleteBooking, useStartBooking } from '../hooks/useBookingQueries';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CommentIcon from '@mui/icons-material/Comment';
import AccessCodeStatus from './AccessCodeStatus';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import SendIcon from '@mui/icons-material/Send';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaymentDrawer from './PaymentDrawer';
import BookingCheckInDialog from './BookingCheckInDialog';
import { useTestPayment } from '../hooks/useTestPayment';

const formatTime = (date: Date | undefined, timezone: string | undefined) => {
  if (!date || !timezone) return '';
  return new Date(date).toLocaleTimeString('ru-RU', { 
    hour: '2-digit', 
    minute: '2-digit',
    timeZone: timezone 
  });
};

const formatTimeLeft = (targetDate: Date): string => {
  const now = new Date();
  const diffMs = targetDate.getTime() - now.getTime();
  const diffMinutes = Math.ceil(diffMs / (1000 * 60));
  
  if (diffMinutes <= 0) return '';
  
  if (diffMinutes > 24 * 60) {
    const days = Math.floor(diffMinutes / (24 * 60));
    return `через ${days} д.`;
  }
  
  if (diffMinutes >= 60) {
    const hours = Math.floor(diffMinutes / 60);
    return `через ${hours} ч.`;
  }
  
  return `через ${Math.max(1, diffMinutes)} мин.`;
};

const isStartDisabled = (booking: Booking | undefined, office: Office | undefined) => {
  if (!booking || !office) return true;
  
  const now = new Date();
  const startTime = new Date(booking.startTime);
  const pauseMinutes = office.duration?.pause || 0;
  const pauseMs = pauseMinutes * 60 * 1000;
  
  return startTime.getTime() - pauseMs > now.getTime() || now.getTime() > booking.endTime.getTime();
};

const BookingDetails: React.FC = () => {
  const { bookingId } = useParams<{ bookingId: string }>();
  const navigate = useNavigate();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [actionComment, setActionComment] = useState('');
  const [commentError, setCommentError] = useState('');
  const [paymentDrawerOpen, setPaymentDrawerOpen] = useState(false);
  const [openCompleteDialog, setOpenCompleteDialog] = useState(false);
  const [openStartDialog, setOpenStartDialog] = useState(false);
  const startMutation = useStartBooking();
  const [, setUpdateTrigger] = useState(0);
  const [searchParams] = useSearchParams();
  const { testPayment } = useTestPayment();

  const { 
    data: booking,
    isLoading,
    error: bookingError
  } = useBooking(bookingId);

  const { 
    data: office,
  } = useOffice(booking?.officeId);

  const cancelMutation = useCancelBooking();
  const completeMutation = useCompleteBooking();

  useEffect(() => {
    if (!booking || !isStartDisabled(booking, office || undefined)) return;
    
    const timer = setInterval(() => {
      setUpdateTrigger(prev => prev + 1);
    }, 60000);
    
    return () => clearInterval(timer);
  }, [booking, office]);

  useEffect(() => {
    const handleTestPayment = async () => {
      if (!booking?.payment?.paymentId) return;
      
      const payParam = searchParams.get('pay');
      if (payParam === null) return;

      try {
        await testPayment(booking.payment.paymentId, {
          isDeadlineExpired: payParam === 'expired',
          isRejected: payParam === 'rejected'
        });
      } catch (error) {
        console.error('Test payment error:', error);
      }
    };

    if (process.env.REACT_APP_ENV === 'development') {
      handleTestPayment();
    }
  }, [booking?.payment?.paymentId, searchParams, testPayment]);

  const handleCancel = async () => {
    if (!bookingId) {
      return;
    }

    if (!actionComment.trim()) {
      setCommentError('Пожалуйста, укажите причину отмены');
      return;
    }

    try {
      await cancelMutation.mutateAsync({ 
        bookingId, 
        comment: actionComment 
      });
      setOpenCancelDialog(false);
      setActionComment('');
      setCommentError('');
    } catch (error) {
      console.error('Error cancelling booking:', error);
    }
  };

  const handleComplete = async ({ photos, comment }: { photos: string[], comment: string }) => {
    if (!bookingId) return;
    
    await completeMutation.mutateAsync({
      bookingId,
      photos,
      comment
    });
  };

  const handleStart = async ({ photos, comment }: { photos: string[], comment: string }) => {
    if (!bookingId) return;
    
    await startMutation.mutateAsync({
      bookingId,
      photos,
      comment
    });
  };

  const canCancel = (booking: any) => {
    if (booking.status !== 'NEW') return false;
    const now = new Date();
    const startTime = new Date(booking.startTime);
    const hoursUntilStart = (startTime.getTime() - now.getTime()) / (1000 * 60 * 60);
    return hoursUntilStart >= 24;
  };

  const canStart = (booking: Booking) => {
    if (booking.status !== 'NEW') return false;
    if (!['CONFIRMED', 'AUTHORIZED'].includes(booking.payment?.status || '')) return false;
    const now = new Date();
    const endTime = new Date(booking.endTime);
    return now < endTime;
  };

  const getStartButtonTooltip = (booking: Booking | undefined) => {
    if (!booking || !office) return '';
    
    const now = new Date();
    const startTime = new Date(booking.startTime);
    const pauseMinutes = office.duration?.pause || 0;
    
    if (now.getTime() > booking.endTime.getTime()) {
      return 'Время бронирования истекло';
    }
    
    if (now.getTime() < startTime.getTime() - (pauseMinutes * 60 * 1000)) {
      return `Начать аренду можно не ранее чем за ${pauseMinutes} минут до начала бронирования`;
    }
    
    return '';
  };

  const canComplete = (booking: any) => {
    if (booking.status === 'IN_PROGRESS') return true;
    if (booking.status !== 'NEW') return false;
    const now = new Date();
    const endTime = new Date(booking.endTime);
    return now >= endTime;
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (bookingError) {
    return (
      <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
        <Typography color="error">
          {bookingError instanceof Error ? bookingError.message : 'Failed to fetch booking details'}
        </Typography>
      </Box>
    );
  }

  if (!booking) {
    return (
      <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
        <Typography>Бронирование не найдено</Typography>
      </Box>
    );
  }

  const formatDate = (date: Date, timezone: string) => {
    return new Date(date).toLocaleDateString('ru-RU', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      timeZone: timezone
    });
  };

  const formatTimeRange = (startDate: Date, endDate: Date, timezone: string) => {
    const options = { hour: '2-digit' as const, minute: '2-digit' as const, timeZone: timezone };
    return `${new Date(startDate).toLocaleTimeString('ru-RU', options)} - ${new Date(endDate).toLocaleTimeString('ru-RU', options)}`;
  };

  const getPaymentStatusText = (status: string) => {
    switch (status) {
      case 'CONFIRMED':
        return 'Оплачено';
      case 'AUTHORIZED':
        return 'Деньги зарервированы';
      case 'PENDING':
        return 'Ожидает оплаты';
      case 'CANCELLED':
        return 'Платеж отменен';
      case 'REJECTED':
        return 'Платеж отклонен';
      case 'REFUNDED':
        return 'Платеж возвращен';
      default:
        return status;
    }
  };

  const formatPrice = (price: number) => {
    return `${(price / 100).toFixed(0)}`;
  };

  const getTelegramMessage = () => {
    const message = encodeURIComponent(
      `Здравствуйте! У меня проблема с оплатой бронирования:\n` +
      `- Номер бронирования: ${booking?.id}\n` +
      `- Дата: ${formatDate(booking?.startTime, booking?.timezone)}\n` +
      `- Время: ${formatTime(booking?.startTime, booking?.timezone)} - ${formatTime(booking?.endTime, booking?.timezone)}\n` +
      `- Сумма: ${booking?.price} ₽\n\n` +
      `Не могу перейти к оплате. Помогите, пожалуйста!`
    );
    return `https://t.me/netolga84?text=${message}`;
  };

  return (
    <Box 
      sx={{ 
        maxWidth: 600, 
        margin: 'auto', 
        mt: 4,
        mb: 8,
        pb: 2,
        pointerEvents: 'none'
      }}
    >
      <Paper 
        elevation={3} 
        sx={{ 
          p: 3,
          pointerEvents: 'auto'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h4">{office?.name}</Typography>
          <Chip 
            label={getStatusText(booking.status)} 
            color={getStatusColor(booking.status) as "primary" | "success" | "info" | "warning" | "error" | "default"}
          />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <LocationOnIcon sx={{ mr: 1, color: 'text.secondary' }} />
          <Typography><strong>Адрес:</strong> {booking.address || 'Адрес не указан'}</Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <EventIcon sx={{ mr: 1, color: 'text.secondary' }} />
          <Typography><strong>Дата:</strong> {formatDate(booking.startTime, booking.timezone)}</Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <AccessTimeIcon sx={{ mr: 1, color: 'text.secondary' }} />
          <Typography>
            <strong>Время:</strong> {formatTimeRange(booking.startTime, booking.endTime, booking.timezone)}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <CreditCardIcon sx={{ mr: 1, color: 'text.secondary' }} />
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {booking.payment?.method === PaymentMethod.FREE ? (
              <Typography color="success.main">
                <strong>Бесплатное бронирование</strong>
              </Typography>
            ) : (
              <>
                <Typography>
                  <strong>Цена:</strong> {booking.totalPrice ? formatPrice(booking.totalPrice) : 'Не указана'}
                </Typography>
                {booking.payment && (
                  <>
                    <Typography sx={{ color: 'text.secondary' }}>•</Typography>
                    <Typography 
                      color={
                        booking.payment.status === 'CONFIRMED' ? 
                          'success.main' : 
                        ['REJECTED', 'CANCELLED', 'REFUNDED'].includes(booking.payment.status) ?
                          'error.main' :
                          'text.secondary'
                      }
                    >
                      {getPaymentStatusText(booking.payment.status)}
                    </Typography>
                  </>
                )}
              </>
            )}
          </Box>
        </Box>

        {booking.booking_comment && (
          <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
            <CommentIcon sx={{ mr: 1, mt: 0.5, color: 'text.secondary' }} />
            <Typography><strong>Комментарий:</strong> {booking.booking_comment}</Typography>
          </Box>
        )}

        {booking.clientName && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>Информация о клиенте</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <PersonIcon sx={{ mr: 1, color: 'text.secondary' }} />
              <Typography>
                <strong>Имя:</strong> {booking.clientName || 'Не указано'}
              </Typography>
            </Box>
            {booking.clientPhone && (
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <PhoneIcon sx={{ mr: 1, color: 'text.secondary' }} />
                <Typography>
                  <strong>Телефон:</strong> {booking.clientPhone}
                </Typography>
              </Box>
            )}
            {booking.clientEmail && (
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <EmailIcon sx={{ mr: 1, color: 'text.secondary' }} />
                <Typography>
                  <strong>Email:</strong> {booking.clientEmail}
                </Typography>
              </Box>
            )}
          </Box>
        )}

        {booking.status === 'NEW' && booking.passes && ['CONFIRMED', 'AUTHORIZED'].includes(booking.payment?.status || '') && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>Коды доступа</Typography>
            {Object.entries(booking.passes).map(([deviceId, pass]) => (
              <Box 
                key={deviceId} 
                sx={{ 
                  mb: 1.5,
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: 1
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                    {pass.name || 'Дверь'}
                  </Typography>
                  <AccessCodeStatus 
                    pass={pass} 
                    bookingCreatedAt={new Date(booking.createdAt)}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        )}
        {booking?.payment && 
          booking.status !== 'CANCELLED' && 
          !['CONFIRMED', 'AUTHORIZED'].includes(booking.payment.status) && 
          booking.payment.method !== PaymentMethod.FREE && (
          <Box sx={{ mt: 3, p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
            <Box sx={{ mt: 3 }}>
              {booking.payment.sbpUrl ? (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => setPaymentDrawerOpen(true)}
                >
                  Оплатить {formatPrice(booking.price)} ₽
                </Button>
              ) : (
                <Alert severity="warning" sx={{ mt: 2 }}>
                  <AlertTitle>Требуется оплата</AlertTitle>
                  <Typography paragraph>
                    Для подтверждения бронирования необходимо произвести оплату. 
                    К сожалению, ссылка на оплату недоступна.
                  </Typography>
                  <Button
                    variant="outlined"
                    color="warning"
                    href={getTelegramMessage()}
                    target="_blank"
                    rel="noopener noreferrer"
                    startIcon={<SendIcon />}
                  >
                    Написать администратору
                  </Button>
                </Alert>
              )}
            </Box>
          </Box>
        )}

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', flexDirection: { xs: 'column', sm: 'row' } }}>
          <Button variant="contained" onClick={() => navigate('/bookings')} sx={{ mb: { xs: 1, sm: 0 } }}>Назад к бронированиям</Button>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {canStart(booking) && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenStartDialog(true)}
                disabled={isStartDisabled(booking, office || undefined)}
                title={getStartButtonTooltip(booking)}
              >
                {isStartDisabled(booking, office || undefined) && booking && office
                  ? `Начать (${formatTimeLeft(new Date(booking.startTime.getTime() - (office.duration?.pause || 0) * 60 * 1000))})` 
                  : 'Начать аренду'}
              </Button>
            )}
            {canComplete(booking) && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenCompleteDialog(true)}
              >
                Завершить аренду
              </Button>
            )}
            {canCancel(booking) && (
              <Button 
                variant="contained" 
                color="error" 
                onClick={() => setOpenCancelDialog(true)}
              >
                Отменить
              </Button>
            )}
          </Box>
        </Box>
      </Paper>

      <Dialog open={openCancelDialog} onClose={() => setOpenCancelDialog(false)}>
        <DialogTitle>Отмена бронирования</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Пожалуйста, укажите причину отмены бронирования:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="cancel-comment"
            label="Комментарий"
            type="text"
            fullWidth
            variant="outlined"
            value={actionComment}
            onChange={(e) => {
              setActionComment(e.target.value);
              setCommentError('');
            }}
            error={!!commentError}
            helperText={commentError}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              setOpenCancelDialog(false);
              setActionComment('');
              setCommentError('');
            }}
            disabled={cancelMutation.isPending}
          >
            Не отменять
          </Button>
          <Button 
            onClick={handleCancel} 
            color="error"
            disabled={cancelMutation.isPending}
            sx={{ minWidth: 120 }}
          >
            {cancelMutation.isPending ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Отменить'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <BookingCheckInDialog
        open={openCompleteDialog}
        onClose={() => setOpenCompleteDialog(false)}
        onSubmit={handleComplete}
        title="Завершение аренды"
        description="Пожалуйста, сделайте фотографии помещения и при необходимости оставьте комментарий:"
        submitButtonText="Завершить"
        isLoading={completeMutation.isPending}
        bookingId={bookingId || ''}
        type="completion"
      />

      <BookingCheckInDialog
        open={openStartDialog}
        onClose={() => setOpenStartDialog(false)}
        onSubmit={handleStart}
        title="Начало аренды"
        description="Пожалуйста, сделайте фотографии помещения перед началом использования:"
        submitButtonText="Начать"
        isLoading={startMutation.isPending}
        bookingId={bookingId || ''}
        type="start"
      />

      <PaymentDrawer
        open={paymentDrawerOpen}
        onClose={() => setPaymentDrawerOpen(false)}
        onOpen={() => setPaymentDrawerOpen(true)}
        paymentUrl={booking?.payment?.sbpUrl || ''}
      />
    </Box>
  );
};

export default BookingDetails;
