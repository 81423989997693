import React, { useState, useEffect } from 'react';
import { FullScreenDrawer } from 'shared-lib';

interface PaymentDrawerProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  paymentUrl: string;
}

const PaymentDrawer: React.FC<PaymentDrawerProps> = ({
  open,
  onClose,
  onOpen,
  paymentUrl
}) => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    if (open) {
      setKey(prev => prev + 1);
    }
  }, [open]);

  return (
    <FullScreenDrawer
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      title="Оплата бронирования"
    >
      <iframe 
        key={key}
        src={paymentUrl || undefined}
        style={{
          width: '100%',
          height: '100%',
          border: 'none'
        }}
        title="payment"
      />
    </FullScreenDrawer>
  );
};

export default PaymentDrawer; 