import React, { useState } from 'react';
import { 
  Typography, Box, Button, Divider, TextField, 
  CircularProgress, FormControlLabel, Checkbox 
} from '@mui/material';
import { Client, Office, Spot, useAuth } from 'shared-lib';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventIcon from '@mui/icons-material/Event';
import ClientSelector from '../ClientSelector';

interface BookingConfirmationStepProps {
  selectedDate: Date;
  selectedSlots: Spot[];
  office: Office;
  onBack: () => void;
  onConfirm: (comment: string, client: Client | null) => Promise<string>;
}

const BookingConfirmationStep: React.FC<BookingConfirmationStepProps> = ({
  selectedDate,
  selectedSlots,
  office,
  onBack,
  onConfirm
}) => {
  const { user } = useAuth();
  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [bookWithoutClient, setBookWithoutClient] = useState(false);
  const [error, setError] = useState('');

  const isAdmin = office?.admins?.some(adminRef => adminRef.id === user?.uid);

  const formatDate = (date: Date) => {
    return date.toLocaleDateString('ru-RU', { 
      weekday: 'long', 
      day: 'numeric', 
      month: 'long' 
    });
  };

  const calculateTotalPrice = () => {
    const totalPrice = selectedSlots.reduce((sum, slot) => sum + (slot.prices || 0), 0);
    if (office.bookingSettings.allow_multiple_slots && office.price.day_price) {
        return Math.min(totalPrice, office.price.day_price);
    }
    
    return totalPrice;
  };

  const formatPrice = (price: number) => `${(price / 100).toFixed(0)} ₽`;

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      setError('');
      const bookingId = await onConfirm(comment, bookWithoutClient ? null : selectedClient);
      if (!bookingId) {
        throw new Error('Failed to create booking');
      }
    } catch (error) {
      console.error('Error during confirmation:', error);
      setError('Произошла ошибка при создании бронирования');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Подтверждение бронирования
      </Typography>
      <Divider sx={{ mb: 2 }} />
      
      <Box sx={{ mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <EventIcon sx={{ mr: 1 }} />
          <Typography>
            Дата: {formatDate(selectedDate)}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <AccessTimeIcon sx={{ mr: 1 }} />
          <Typography>
            Время: {selectedSlots[0].start} - {selectedSlots[selectedSlots.length - 1].end}
          </Typography>
        </Box>
      </Box>

      {!bookWithoutClient && (
        <ClientSelector
          onClientSelect={setSelectedClient}
          selectedClient={selectedClient}
        />
      )}

      <FormControlLabel
        control={
          <Checkbox
            checked={bookWithoutClient}
            onChange={(e) => {
              setBookWithoutClient(e.target.checked);
              if (e.target.checked) {
                setSelectedClient(null);
              }
            }}
          />
        }
        label="Бронировать без указания клиента"
      />

      <TextField
        fullWidth
        label="Комментарий"
        multiline
        rows={4}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        variant="outlined"
        margin="normal"
      />

      <Divider sx={{ my: 2 }} />
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography>Всего</Typography>
        <Typography fontWeight="bold">
          {isAdmin ? 
            'Бесплатно (администратор)' : 
            formatPrice(calculateTotalPrice())
          }
        </Typography>
      </Box>

      {error && (
        <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>
          {error}
        </Typography>
      )}

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={onBack}>
          Назад
        </Button>
        <Button
          variant="contained"
          onClick={handleConfirm}
          disabled={isLoading || (!bookWithoutClient && !selectedClient)}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Забронировать'}
        </Button>
      </Box>

      <Typography variant="caption" sx={{ display: 'block', mt: 2, textAlign: 'center' }}>
        Записываясь, вы даете согласие на обработку своих персональных данных.
      </Typography>
    </Box>
  );
};

export default BookingConfirmationStep; 